var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allcontens"},[_c('div',{staticClass:"content"},[_vm._m(0),_c('van-form',{staticClass:"form",on:{"submit":_vm.onSubmit,"failed":_vm.onFailed}},[_c('van-field',{attrs:{"required":"","name":"name","label":"真实姓名","placeholder":"请输入姓名","input-align":"right","rules":[{ required: true }]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('van-field',{attrs:{"required":"","placeholder":"请填写手机号","input-align":"right","name":"phone","type":"tel","label":"手机号码","rules":[
          {
            required: true,
            pattern:
              /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: '手机号格式错误',
          },
        ]},model:{value:(_vm.formData.phone),callback:function ($$v) {_vm.$set(_vm.formData, "phone", $$v)},expression:"formData.phone"}}),_c('van-field',{attrs:{"required":"","name":"idCard","label":"身份证号码","placeholder":"请输入身份证号码","input-align":"right","rules":[
          {
            required: true,
            pattern: /^(\d{15}$)|(^\d{17}([0-9]|X))$/,
            message: '身份证格式错误',
          },
        ]},model:{value:(_vm.formData.idCard),callback:function ($$v) {_vm.$set(_vm.formData, "idCard", $$v)},expression:"formData.idCard"}}),_c('van-field',{attrs:{"required":"","name":"businessName","label":"业务名称","maxlength":"6","placeholder":"请输入业务名称","input-align":"right","rules":[{ required: true }]},model:{value:(_vm.formData.businessName),callback:function ($$v) {_vm.$set(_vm.formData, "businessName", $$v)},expression:"formData.businessName"}}),_c('van-field',{attrs:{"required":"","name":"name","label":"联系地址","placeholder":"请输入联系地址","input-align":"right","rules":[{ required: true }]},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", $$v)},expression:"formData.address"}}),_c('div',{staticClass:"idCarUpload"},[_c('div',{staticClass:"positive"},[_c('c-uploader',{attrs:{"uploadIconUrl":_vm.idFrontPic,"uploadText":"上传身份证正面","size":2,"uploadUrl":_vm.uploadImg},on:{"upload":function($event){return _vm.uploadImgFun('front', $event)}}})],1),_c('div',{staticClass:"theOtherSide"},[_c('c-uploader',{attrs:{"uploadIconUrl":_vm.idReversePic,"uploadText":"上传身份证反面","size":2,"uploadUrl":_vm.uploadImg},on:{"upload":function($event){return _vm.uploadImgFun('reverseSide', $event)}}})],1),(_vm.errorMsg)?_c('div',{staticClass:"error"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")]):_vm._e()]),_c('hr'),_c('p',{staticClass:"tips"},[_vm._v(" 提示：请上传清晰的身份证正反面，以png, jpeg, jpg格式，单张图片大小不超过2M。 ")]),_c('div',{staticClass:"row justify-center"},[_c('van-button',{staticClass:"next-btn",attrs:{"round":"","block":"","type":"info","size":"small","native-type":"submit","loading":_vm.loading}},[_vm._v("提交信息")])],1)],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepsBox"},[_c('div',{staticClass:"stepBox"},[_c('p',{staticClass:"stepTitle"},[_c('span',{staticClass:"stepNum stepNum1"},[_vm._v("1")]),_vm._v(" 填写基本信息 ")]),_c('p',{staticClass:"stepSubTitle"},[_vm._v("填写基本信息")])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"stepBox stepBox2"},[_c('p',{staticClass:"stepTitle"},[_c('span',{staticClass:"stepNum"},[_vm._v("2")]),_vm._v(" 征信授权")]),_c('p',{staticClass:"stepSubTitle"},[_vm._v(" 填写完信息，收到征信授权短信， 进行实名认证/电子签名。 ")])])])
}]

export { render, staticRenderFns }