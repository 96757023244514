<template>
  <div class="allcontens">
    <div class="content">
      <div class="stepsBox">
        <div class="stepBox">
          <p class="stepTitle">
            <span class="stepNum stepNum1">1</span>
            填写基本信息
          </p>
          <p class="stepSubTitle">填写基本信息</p>
        </div>
        <div class="line"></div>
        <div class="stepBox stepBox2">
          <p class="stepTitle"><span class="stepNum">2</span> 征信授权</p>
          <p class="stepSubTitle">
            填写完信息，收到征信授权短信， 进行实名认证/电子签名。
          </p>
        </div>
      </div>
      <van-form @submit="onSubmit" @failed="onFailed" class="form">
        <van-field required v-model="formData.name" name="name" label="真实姓名" placeholder="请输入姓名" input-align="right"
          :rules="[{ required: true }]" />
        <van-field required placeholder="请填写手机号" v-model="formData.phone" input-align="right" name="phone" type="tel"
          label="手机号码" :rules="[
            {
              required: true,
              pattern:
                /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
              message: '手机号格式错误',
            },
          ]" />
        <van-field required v-model="formData.idCard" name="idCard" label="身份证号码" placeholder="请输入身份证号码"
          input-align="right" :rules="[
            {
              required: true,
              pattern: /^(\d{15}$)|(^\d{17}([0-9]|X))$/,
              message: '身份证格式错误',
            },
          ]" />
        <van-field required v-model="formData.businessName" name="businessName" label="业务名称" maxlength="6"
          placeholder="请输入业务名称" input-align="right" :rules="[{ required: true }]" />
        <van-field required v-model="formData.address" name="name" label="联系地址" placeholder="请输入联系地址"
          input-align="right" :rules="[{ required: true }]" />
        <div class="idCarUpload">
          <div class="positive">
            <c-uploader :uploadIconUrl="idFrontPic" uploadText="上传身份证正面" @upload="uploadImgFun('front', $event)"
              :size="2" :uploadUrl="uploadImg">
            </c-uploader>
          </div>
          <div class="theOtherSide">
            <c-uploader :uploadIconUrl="idReversePic" uploadText="上传身份证反面" @upload="uploadImgFun('reverseSide', $event)"
              :size="2" :uploadUrl="uploadImg"></c-uploader>
          </div>
          <div class="error" v-if="errorMsg">
            {{ errorMsg }}
          </div>
        </div>
        <hr />
        <p class="tips">
          提示：请上传清晰的身份证正反面，以png, jpeg,
          jpg格式，单张图片大小不超过2M。
        </p>
        <div class="row justify-center">
          <van-button round block type="info" class="next-btn" size="small" native-type="submit"
            :loading="loading">提交信息</van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>
<script>
import cUploader from "@/components/cuploaderV2";
import idFrontPic from "@/assets/uploadimg/shenfenzhengzhengmian.svg";
import idReversePic from "@/assets/uploadimg/shenfenzhengbeimian.svg";
import { investigationSubmit, uploadImg } from "@/api/credit";

export default {
  name: "auth",
  components: { cUploader },
  data() {
    return {
      idFrontPic,
      idReversePic,
      errorMsg: "",
      insuranceNo: "",
      formData: {
        name: "",
        phone: "",
        // idCardType: "",
        idCard: "",
        businessName: "车辆贷款查询",
        idCardFront: "",
        idCardBack: "",
        address: "",
      },
      loading: false,
      orderNo: "",
      groupId: "",
      uploadImg,
    };
  },
  async created() {
    // this.orderNo = this.$route.query.orderNo;
    this.groupId = this.$route.query.groupId;
    this.userId = this.$route.query.userId;
  },
  methods: {
    async onSubmit(values) {
      this.loading = true;
      if (!this.formData.idCardFront || !this.formData.idCardBack) {
        this.errorMsg = "请上传身份证正、反面图片";
        this.loading = false;
        return;
      }
      try {
        const res = await investigationSubmit({
          ...this.formData,
          groupId: this.groupId,
          userId: this.userId,
        });
        this.loading = false;
        this.responseTip(res.msgCode);
        if (res.code !== 10000000) {
          console.log(res);
          this.$toast(res.msgCode || "系统错误");
          return;
        }
        this.orderNo = res.data;
        this.$router.push({
          path: `/auth/${this.orderNo}`,
        });
        // this.$router.push({
        //   path: "/credit/realPersonAuth",
        //   query: {
        //     orderNo: this.orderNo,
        //   },
        // });
      } catch (e) {
        this.loading = false;
        this.$toast("提交信息异常，请联系客服");
      }
    },
    // credit.idcardInvalid
    responseTip(str) {
      switch (str) {
        case "credit.nameEmpty":
          this.$toast("客户姓名不能为空");
          break;
        case "credit.phoneEmpty":
          this.$toast("客户手机号不能为空");
          break;

        case "credit.idCardEmpty":
          this.$toast("客户身份证号不能为空");
          break;
        case "credit.busNameEmpty":
          this.$toast("业务名称不能为空");
          break;
        case "credit.idFrontEmpty":
          this.$toast("身份证正面照未上传");
          break;
        case "credit.idCardError":
          this.$toast("身份证上传错误");
          break;
        case "credit.authError":
          this.$toast("授权书上传错误");
          break;
        case "credit.idBackEmpty":
          this.$toast("身份证反面照未上传");
          break;
        case "credit.submitSuccess":
          this.$toast("提交成功");
          break;
        case "credit.submitFail":
          this.$toast("提交失败");
          break;
        case "credit.orderNoEmpty":
          this.$toast("单号不能为空");
          break;
        case "credit.orderNoNotExist":
          this.$toast("订单号不存在");
          break;
        case "credit.phoneInvalid":
          this.$toast("无效的手机号码");
          break;
        case "credit.idcardInvalid":
          this.$toast("无效的身份证号码");
          break;
      }
    },
    onFailed() {
      // this.$toast("提交信息异常");
    },
    uploadImgFun(flat, uploadContent) {
      if (flat === "front") {
        this.formData.idCardFront = uploadContent;
      } else {
        this.formData.idCardBack = uploadContent;
      }
    },
  },
};
</script>
<style scoped>
.sp {
  font-size: 0.28rem;
  display: inline-block;
  width: 1.4rem;
}

.borderbt {
  border-bottom: 1px solid #e4e7ed;
  padding: 0.1rem 0.2rem 0.1rem 0.2rem;
  font-size: 0.28rem;
}

.upload-imagef,
.upload-imagez {
  border: 0;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0.4rem;
  box-sizing: border-box;
  width: 3.5rem;
}

.indexinput {
  border: 0;
  outline: none;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0.4rem;
  box-sizing: border-box;
  width: 4rem;
  color: #666;
  /* font-size: 0.3rem; */
}

.idCarUpload {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.6rem;
}

.imgsize {
  width: 2rem;
  height: 1.36rem;
}

.upload-imagef,
.upload-imagez {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.theOtherSide,
.positive {
  position: relative;
  width: 250px;
  height: 160px;
  border: 1px dashed #9b9fa5;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  margin-bottom: 30px;
}

.upsp {
  font-size: 0.25rem;
  margin-top: 0.15rem;
  color: #666;
  font-weight: 600;
}

.echo-imgZ,
.echo-imgF {
  position: absolute;
  width: 100%;
  height: 100%;
}

.noneborder {
  border-bottom: none;
}

.next {
  width: 4rem;
  height: 0.8rem;
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
  font-weight: 600;
  color: #fff;
  text-align: center;
  line-height: 0.8rem;
  border-radius: 0.6rem;
  background: #0084fe;
}

.tips {
  color: #999;
  padding: 0.3rem;
  font-size: 0.28rem;
}

.error {
  color: red;
  padding-top: 3px;
  padding-bottom: 20px;
  font-size: 14px;
}

.stepsBox {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.stepNum {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: #999999;
  font-size: 16px;
  border: 1px solid #999999;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.stepNum1 {
  background: #0084fe;
  border-color: #0084fe;
  color: #ffffff;
}

.stepBox {
  text-align: center;
  width: 42.5%;
}

.line {
  width: 15%;
  height: 1px;
  background: #9b9fa5;
  margin-top: 15px;
}

.stepTitle {
  font-size: 16px;
  color: #333333;
}

.stepSubTitle {
  font-size: 12px;
  color: #999999;
}
</style>
